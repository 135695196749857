import React from 'react'

import SeparadorPrincipal from '../SeparadorPrincipal/SeparadorPrincipal.jsx'
import Bienvenida from './Bienvenida/Bienvenida.jsx'
import Pilares from './Pilares/Pilares.jsx'
import Conferencias from './Conferencias/Conferencias.jsx'
import Talleres from './Talleres/Talleres.jsx'
import Visitas from './Visitas/Visitas.jsx'
import Nosotros from './Nosotros/Nosotros.jsx'

const Home = () => {
  return (
    <>
      <Bienvenida />
      <SeparadorPrincipal altoDelSeparador = '42px'/>
      <Pilares />
      <Conferencias />
      <Talleres />
       <Visitas />
      <Nosotros /> 
    </>
  )
}

export default Home