import React from 'react';
import styles from './Talleres.module.css';
import BotonVer from '../../BotonVer/BotonVer';
import Titulo from '../../Titulo/Titulo';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {talleres} from '../../../data/talleres.js';

import equipo from "../../../assets/images/talleres_home/equipo.png";
import gimnasio from "../../../assets/images/talleres_home/gimnasio.png";
import just from "../../../assets/images/talleres_home/just.png";
import negociacion from "../../../assets/images/talleres_home/negociacion.png";

const Talleres = () => {

  const tarjetas = [{
    imagen: just,
    titulo1: "Just in time",
    titulo2: "En acción"
  },
  {
    imagen: negociacion,
    titulo1: "Negociacion",
    titulo2: "Maestra"
  },
  {
    imagen: gimnasio,
    titulo1: "Gimnasio de",
    titulo2: "Innovación"
  },
  {
    imagen: equipo,
    titulo1: "Al ritmo del",
    titulo2: "Equipo"
  }
]
  return (
    <Container>

      <Titulo texto='Talleres' />

      <Row className="d-flex align-items-center justify-content-around">
        {tarjetas.map((tarjeta, index) => (
          <Col xs={8} md={6} lg={3} key={index} className={`d-flex text-center justify-content-center ${styles.cardBox}`}>
            <div className={styles.tarjeta}>
              <img src={tarjeta.imagen} alt={tarjeta.titulo1} className={styles.imagen} />
              <h3 className={styles.titulo}>{tarjeta.titulo1}</h3>
              <h3 className={styles.titulo}>{tarjeta.titulo2}</h3>
            </div>
          </Col>
        ))  
        }
      </Row>

      <Link to="/talleres" className={styles.boton}>
        <BotonVer texto='Ver todos' />
      </Link>

    </Container>
  );
};

export default Talleres;
