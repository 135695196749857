import iglogo from '../assets/icons/iglogo.svg';
import maillogo from '../assets/icons/maillogo.svg';
import twitterlogo from '../assets/icons/twitterlogo.svg';
import LinkedInlogo from '../assets/icons/LinkedInlogo.svg';
import spotifylogo from '../assets/icons/spotifylogo.svg';
import youtubelogo from '../assets/icons/youtubelogo.svg';
import weblogo from '../assets/icons/weblogo.svg';

import ideas from '../assets/images/tarjetas/ideas.png';
import impacto from '../assets/images/tarjetas/impacto.png';
import innovacion from '../assets/images/tarjetas/innovacion.png';
import revoluciones from '../assets/images/tarjetas/revoluciones.png';

import monica from '../assets/images/conferencias/monica.png';
import marianela from '../assets/images/conferencias/marianela.png';
import juanangel from '../assets/images/conferencias/juanangel.png';
import francisco from '../assets/images/conferencias/francisco.png';
import pablo from '../assets/images/conferencias/pablo.png';
import luisinapatricio from '../assets/images/conferencias/luisinapatricio.png';
import deborah from '../assets/images/conferencias/deborah.png';
import gustavo from '../assets/images/conferencias/gustavo.png';
import ariel from '../assets/images/conferencias/ariel.png';
import barbara from '../assets/images/conferencias/barbara.png';
import tomas from '../assets/images/conferencias/tomas.png';
import flip from '../assets/images/conferencias/flip.png';
export const conferencias = [
  {
    ponente: 'mónica izaguirre',
    nombre: 'Maestros de las predicciones.',
    shortText:
      'Nuestro cerebro anticipa la realidad. Muchas de esas predicciones nos paralizan a estar falsamente convencidos de que no podemos. En esta charla aprenderás a cómo liberarte de algunas creencias que no te dejan tomar las decisiones que te permitirán conseguir otro tipo de resultados.',
    resumen: undefined,
    descripcionProfesional: [
      'Es comunicadora y coach. Desde hace más de 20 años, se dedica a acompañar a personas y organizaciones para que consigan los resultados deseados.',
      'Directora de la consultora Embrión2, que ofrece servicios personalizados de capacitación, coaching y consultoría. La mayor parte de estos servicios, se realizan a través de la modalidad a distancia, lo cual le ha permitido llegar a miles de profesionales y organizaciones de toda América Latina. Tiene su canal de podcast en Spotify, "Las Pastillas de la Abuela", que ya va por su tercera temporada. Un programa escuchado en más de 25 países, que aborda, con una mirada cítrica (y crítica), los problemas de las organizaciones y sus distintos actores.',
      'Es docente universitaria en Argentina, Chile y Uruguay.',
    ],
    tarjeta: ideas,
    duracion: '20’ + 15’ de preguntas',
    keywords: [
      'creencias',
      'predicciones',
      'desarrollopersonal',
      'coaching',
      'tomardecisiones',
      'decisionesinteligentes',
      'liderazgopersonal',
      'exito',
    ],
    redes: [
      {
        logo: weblogo,
        texto: 'Consultora',
        url: 'https://embrion2.com/',
      },
      {
        logo: spotifylogo,
        texto: 'Podcast',
        url: 'https://open.spotify.com/show/5KsCRR2HNiOexoplRfx0Cv',
      },
      {
        logo: youtubelogo,
        texto: 'YouTube',
        url: 'https://www.youtube.com/channel/UCvV5DZuUIRBNFS9-_NjxUZQ',
      },
      {
        logo: iglogo,
        texto: 'Instagram',
        url: 'https://www.instagram.com/embrion2',
      },
      {
        logo: LinkedInlogo,
        texto: 'LinkedIn',
        url: 'https://www.LinkedIn.com/in/monicaizaguirre/',
      },
    ],
    imagen: monica,
  },

  {
    ponente: 'marianela pepe',
    nombre: 'Ingeniando un futuro basado en el cuidado ambiental.',
    shortText:
      'Alguna vez te preguntaste qué podés hacer, desde tu rol de estudiante de ingeniería para cuidar el planeta? Muchas veces creemos que estando tan lejos del resto del mundo no podemos hacer nada.. Sin embargo, es más que posible y como jóvenes y futuros profesionales podemos elevar nuestras voces de diversas maneras.',
    resumen: undefined,
    descripcionProfesional: [
      'Marianela Pepe es estudiante de la carrera de Ingeniería Química en la Universidad Nacional del Comahue en Neuquén.',
      'Con 21 años, Marianela participó de programas internacionales, proyectos de investigación sobre la importancia del cuidado del medio ambiente en Junín de los Andes, su ciudad natal, y ha presentado dichos proyectos en congresos, premiaciones, clases, etc.',
      'Actualmente Marianela participa de programas nacionales como los Exploradores Argentinos de Don Bosco e internacionales como el Consejo de Jóvenes Líderes de EarthEcho International, una organización que se enfoca en el cuidado del agua y en elevar las voces de los jóvenes para promover un futuro sustentable.',
    ],
    tarjeta: impacto,
    duracion: '20’ + 10’ de preguntas',
    keywords: [
      'ambiente',
      'desarrollo sostenible',
      'investigación ambiental',
      'liderazgo',
    ],
    redes: [
      {
        logo: weblogo,
        texto: 'GenSea',
        url: 'https://joingensea.org/users/3239289',
      },
      {
        logo: iglogo,
        texto: 'Instagram',
        url: 'https://www.instagram.com/marianela.pepe/?hl=es',
      },
      {
        logo: LinkedInlogo,
        texto: 'LinkedIn',
        url: 'https://www.LinkedIn.com/in/marianela-pepe',
      },
    ],
    imagen: marianela,
  },

  {
    ponente: 'juan ángel garcía wall',
    nombre: 'Si la vida me da un limón... me hago una capirinha.',
    shortText:
      'Si comprendemos el tortuoso camino de las ideas sabremos interpretar las oportunidades que se presentan y cómo sacar el mayor provecho. Podremos decidir qué podemos hacer ahora y qué desafíos deberán esperar a que estemos preparados para afrontarlos.',
    resumen: [
      'Construir una manera de pensar nos ayuda a navegar por este camino. Pensar antes de decidir es fundamental; nos permite evaluar todas las posibilidades y sopesar las consecuencias. Recordemos siempre que hay varias alternativas posibles, y es nuestra responsabilidad encontrar la que mejor se adapte a la situación.',
      'Al enfrentarnos a la toma de decisiones, el desafío está en cómo llegar a la mejor idea. No se trata solo de nuestras preferencias personales, sino de evaluar objetivamente las opciones y considerar cómo impactarán en nuestro objetivo. Este proceso requiere paciencia y análisis.',
      "En última instancia, lo que aprendemos es que la construcción de un pensamiento sólido es esencial. Nos capacita para comprender nuestras limitaciones y fortalezas. Como decía el Capitán Jack Sparrow: 'Lo importante es saber lo que puede hacer uno y lo que no puede hacer uno.'",
    ],
    descripcionProfesional: ['Licenciado en Marketing, curioso y griter.'],
    tarjeta: ideas,
    duracion: '20’ + 15’ de preguntas',
    keywords: ['pensar', 'preguntarse', 'desafiarse', '¿Por qué no?'],
    redes: undefined,
    imagen: juanangel,
  },

  {
    ponente: 'francisco buchara',
    nombre: 'Start Ups y Clusters biotecnológicos',
    shortText:
      'SF500 es una organización que tiene como objetivo promover la vinculación público privada que tiene como objetivo crear 500 startups y consolidar un clúster biotecnológico con epicentro en Rosario, Argentina. Enterate como en la conferencia de Francisco Buchara.',
    resumen: undefined,
    descripcionProfesional: [
      'Francisco es cofundador y CEO de SF500. Su vocación es promover la articulación público-privada para resolver retos complejos y su pasión es poner la ciencia y la tecnología al servicio de las personas.',
      'Actualmente, como CEO de SF500, Francisco lidera el proceso de creación de startups mas grande de América Latina con foco en Ciencias de la Vida. Su propósito es contribuir a la creación de 500 startups y consolidar un cluster biotecnológico con epicentro en Rosario, Argentina.',
      'Francisco es licenciado en Ciencias Empresariales por la Universidad Austral de Rosario. Tiene un MBA del IAE Business School, y es egresado del programa ILG de la Universidad de Georgetown y Eisenhower Fellow en 2019.',
    ],
    tarjeta: ideas,
    duracion: '20’ + 15’ de preguntas',
    keywords: ['SF500', 'startups', 'biotecnológico'],
    redes: [
      {
        logo: iglogo,
        texto: 'Instagram SF500',
        url: 'https://www.instagram.com/sf500fund/?igshid=MzRlODBiNWFlZA%3D%3D',
      },
      {
        logo: LinkedInlogo,
        texto: 'LinkedIn SF500',
        url: 'https://www.LinkedIn.com/company/sf500/',
      },
      {
        logo: LinkedInlogo,
        texto: 'LinkedIn Franciso',
        url: 'https://www.LinkedIn.com/in/f-buchara/',
      },
    ],
    imagen: francisco,
  },

  {
    ponente: 'pablo mellicovsky',
    nombre:
      'Mindset 4.0: La Evolución del Ingeniero en la Industria del futuro.',
    shortText:
      'Descubre cómo los ingenieros industriales pueden liderar la revolución tecnológica, haciendo un cambio de mentalidad hacia la ingeniería de valor y desarrollando habilidades digitales que desbloquean el cambio',
    resumen:
      ['Juntos, exploramos la industria del futuro y lo que viene, y cómo podemos transformar el panorama industrial Argentina. ¡Te esperamos para inspirarte y motivarte a ser protagonista del desafío tecnológico que ya está ocurriendo!'],
    descripcionProfesional: [
      'Pablo Mellicovsky es un experto en el campo de la tecnología de gestión aplicada a la industria, con más de 15 años de experiencia en empresas multinacionales de tecnología.',
      'Durante su trayectoria, ha liderado proyectos innovadores y de transformación digital en diversas organizaciones, impulsando la adopción de tecnologías avanzadas, creando soluciones disruptivas.',
      'Actualmente, lidera la consultora INDX, enfocada en la integración de tecnología para la industria 4.0.',
      'Con su pasión por la tecnología y su visión estratégica, Pablo continúa inspirando a otros a abrazar la innovación y el liderazgo en el ámbito de la ingeniería industrial.',
    ],
    tarjeta: revoluciones,
    duracion: '20’ + 15’ de preguntas',
    keywords: [
      'Mindset',
      'Ingeniería de valor',
      'Estrategia',
      'Prevision',
    ],
    redes: [
      {
        logo: LinkedInlogo,
        texto: 'LinkedIn',
        url: 'https://www.LinkedIn.com/in/pablomellicovsky/',
      },
    ],
    imagen: pablo,
  },

  {
    ponente: 'neural soft',
    nombre: 'Ingenieros Industriales en el mundo IT – Mirando hacia el futuro.',
    shortText:
      'La tecnología cambió y sigue cambiando la vida de las personas y de las empresas. Como Ingenieros Industriales, ¿cómo podemos aportar valor a esa transformación? Nuestra posibilidad de encontrar soluciones ingeniosas a problemas complejos es una de las habilidades más requeridas en el mundo IT.',
    resumen:
      ['¡Te invitamos a conocer nuestra experiencia como Ingenieros Industriales en el rubro del desarrollo de softwares!'],
    descripcionProfesional: [
      'Patricio Iwanowski - Ingeniero Industrial de la UNR.',
      'Arquitecto de Software en Neuralsoft.',
      'La industria del software es muy dinámica y cambiante por naturaleza, y es por ello que necesita el aporte de personas creativas y disruptivas. Personalmente creo que los ingenieros industriales tenemos un enorme potencial para desarrollarnos en el sector, principalmente debido a nuestra habilidad para resolver problemas complejos y ofrecer soluciones ingeniosas que se adapten a las necesidades de los clientes.',
      '',
      'Luisina Cominelli - Estudiante de Ingeniería Industrial de la UNR.',
      'Líder de Gestión del conocimiento en Neuralsoft.',
      'En el mundo IT, encuentro constantemente la oportunidad de crecer y desarrollarme profesionalmente, nutrir mis habilidades creativas e innovadoras, y enfrentarme a nuevos desafíos, todos los días. Como ingeniera industrial, me interesa explorar diversos rubros y conocer distintas empresas, desde adentro. Con la ayuda de MyLogic, nuestro software ERP, puedo comprender en profundidad el funcionamiento de cada negocio y seguir aprendiendo de manera continua.',
    ],
    tarjeta: ideas,
    duracion: '20’',
    keywords: [
      'Desarrollo profesional',
      'Crecimiento',
      'Aprendizaje',
      'Innovación',
    ],
    redes: [
      {
        logo: LinkedInlogo,
        texto: 'LinkedIn Luisina',
        url: 'https://www.LinkedIn.com/in/luisina-cominelli/',
      },
      {
        logo: LinkedInlogo,
        texto: 'LinkedIn Patricio',
        url: 'https://www.LinkedIn.com/in/luisina-cominelli/',
      },
    ],
    imagen: luisinapatricio,
  },

  {
    ponente: 'deborah sabsay',
    nombre: 'El rol de los jóvenes profesionales en nuevos mercados de impacto.',
    shortText:
      'El rol de los futuros profesionales en estos nuevos mercados de impacto donde lo que se busca no es solamente maximizar la rentabilidad de los accionistas si no también poner en el centro los impactos sociales y ambientales.',
    resumen: undefined,
    descripcionProfesional: [
      'Kausana, agencia de impacto cuyo propósito es generar mercados de impacto económico y socioambiental, sensibilizando a los distintos actores de la comunidad sobre la temática de las nuevas economías del bien común. Acompañamos a las empresas a iniciar el camino de transformación hacia un modelo de negocios con propósito y triple impacto.',
    ],
    tarjeta: impacto,
    duracion: '20’ + 10’ de preguntas',
    keywords: [
      'Futuro',
      'Mercado',
      'Impacto',
      'Economía',
    ],
    redes: [
      {
        logo: LinkedInlogo,
        texto: 'LinkedIn Deborah',
        url: 'https://www.LinkedIn.com/in/d%C3%A9borah-sabsay-47994785/',
      },
      {
        logo: LinkedInlogo,
        texto: 'LinkedIn Mayra',
        url: 'https://www.LinkedIn.com/in/amine-mayra-fullana-47773a10/',
      },
    ],
    imagen: deborah,
  },

  {
    ponente: 'gustavo rodriguez',
    nombre: 'Liderazgo Sustentable, 3 historias para inspirarte.',
    shortText:
      'El cuidado de nuestro planeta necesita de líderes transformadores, que no solo convenzan con sus palabras a las personas, sino también con sus hechos. En cada área de la cual somos responsables debemos ser transformadores para que nuestro impacto sea cero no alcanza con tener ganas y voluntad.',
    resumen:
      ['Es imprescindible ser líder transformador. Disfrutarán 3 historias increíbles, con finales sorprendentes. No será una clase, será un relato atrapante de cómo un ingeniero industrial puede dejar un legado en el mundo y las personas.'],
    descripcionProfesional: [
      'Gustavo Rodríguez, ingeniero químico graduado de la Universidad de Buenos Aires, es autor del libro “El Liderazgo del Futuro” y creador de T2T Academy, una academia especializada en el desarrollo de jóvenes profesionales.',
      'Además, es director de la carrera de Ingeniería Industrial en el Instituto Tecnológico de Buenos Aires (ITBA) y anteriormente se desempeñó como director de cadena de abastecimiento para Latinoamérica en Unilever.',
      'Gustavo es un asesor de empresas, speaker internacional y educador disruptivo. Comparte su mensaje perspicaz y poderoso con audiencias alrededor del mundo, inspirándolas a través de sus relatos.',
      'Con más de 20 años de experiencia en el desarrollo profesional, ha plasmado sus conocimientos en T2T Academy, beneficiando a numerosos individuos que ahora ocupan posiciones exitosas en empresas, desde vicepresidentes hasta gerentes.',
      'Con expertise en fabricación, gestión de demanda y suministro, gestión del cambio y liderazgo sustentable, es reconocido como un gran creador del futuro de las operaciones.',
    ],
    tarjeta: impacto,
    duracion: '20’ + 15’ de preguntas',
    keywords: ['Liderazgo', 'Sustentable', 'Inspirar', 'Transformar'],
    redes: [
      {
        logo: weblogo,
        texto: 'Biografía',
        url: 'https://gustavorodriguez.ar/',
      },
      {
        logo: weblogo,
        texto: 'Academia',
        url: 'https://t2tacademy.teachable.com/',
      },
      {
        logo: LinkedInlogo,
        texto: 'LinkedIn',
        url: 'https://www.LinkedIn.com/company/t2t-academy/',
      },
      {
        logo: youtubelogo,
        texto: 'YouTube',
        url: 'https://www.youtube.com/channel/UCaD9O2vA88C3TKJ48A-DX8A',
      },
    ],
    imagen: gustavo,
  },

  {
    ponente: 'ariel goldvarg',
    nombre:
      '¡Puede fallar! Un recorrido profesional a través de la implementación de mejora continua.',
    shortText:
      'Un ingeniero que fue a estudiar a Japón a aprender la posta de la mejora continua. Derribar el mito que dice “en Argentina no se puede hacer Kaizen” y ¿Cómo hacer un VSM y no morir en el intento?',
    resumen: undefined,
    descripcionProfesional: [
      'En mi recorrido profesional asesorando empresas (de múltiples rubros y tamaños) he podido llevar adelante exitosos proyectos de mejora.',
      'Pero el aprendizaje más profundo ocurrió en aquellas en las que no pude conseguir los resultados esperados.',
      'Me diplomé en Lean Manufacturing en México y me certifiqué como especialista en Japón y puedo concluir que la mejora continua (Lean Manufacturing, Método Toyota, o como quieran llamarlo) es muy simple de comprender pero extremadamente difícil de implementar.',
      "Voy a contarles las claves para comenzar a implementar esta metodología y herramientas que les permitan saber si 'van bien' o hay que abandonar el barco.",
    ],
    tarjeta: innovacion,
    duracion: '20’ + 15’ de preguntas',
    keywords: ['Mejora Continua', 'Kaizen', 'Japon', 'VSM'],
    redes: [
      {
        logo: LinkedInlogo,
        texto: 'LinkedIn',
        url: 'https://www.LinkedIn.com/in/arielgoldvarg/',
      },
    ],
    imagen: ariel,
  },

  {
    ponente: 'barbara cecarelli',
    nombre:
      'Desafiando los Límites: El Poder de la Ingeniería Industrial en un Mundo en Constante Transformación.',
    shortText:
      'En esta conferencia, exploraremos las valiosas oportunidades que se presentan a los ingenieros industriales en un mundo en constante evolución, enfocándonos en los tres pilares fundamentales: social, medioambiental y económico.',
    resumen:
      ['Desde la promoción de la innovación y la tecnología hasta la transformación de los procesos y las organizaciones, descubriremos cómo abordar de manera integral estos pilares conduce hacia un futuro sostenible.'],
    descripcionProfesional: [
      'Ingeniera Mecánica, Ingeniera Industrial, Especialista en Ingeniería Ambiental, Auditora Líder en Sistemas de Gestión de Calidad, Auditora Líder Trinorma (ISO 9001, 14001, 45001).',
      'Cofounder de EIG ENGINEERING CONSULTING, empresa dedicada a la consultoría de empresas y organizaciones.',
    ],
    tarjeta: innovacion,
    duracion: '20’ + 15’ de preguntas',
    keywords: ['Futuro', 'Pportunidades', 'Sostenibilidad'],
    redes: [
      {
        logo: weblogo,
        texto: 'Eigengineering',
        url: 'https://www.eigengineering.com/',
      },
      {
        logo: maillogo,
        texto: 'Correo',
        url: 'mailto:bceccarelli@eigengineering.com',
      },
      {
        logo: LinkedInlogo,
        texto: 'LinkedIn',
        url: 'https://www.LinkedIn.com/company/eig-engineering-consulting/',
      },
      {
        logo: LinkedInlogo,
        texto: 'LinkedIn Barbara',
        url: 'https://www.LinkedIn.com/in/barbara-ceccarelli',
      },
    ],
    imagen: barbara,
  },

  {
    ponente: 'tomás karagozian',
    nombre: 'Tecnología, producción y desarrollo.',
    shortText:
      'Tecnología e inteligencia artificial aplicado a los procesos productivos. Sinergia y tracción para un desarrollo sostenible. Articulación y redes.',
    resumen: undefined,
    descripcionProfesional: [
      'Tomás Karagozian es CEO de TN&Platex, una de las empresas textiles líderes de la Argentina y Sudamérica. Con 6 complejos industriales en el interior del país, un centro de distribución y oficinas administrativas distribuidas en 7 provincias, la empresa cuenta con  200.000 m2 productivos y emplea en forma directa a 1.700 colaboradores.',
      'Tomás es Licenciado en Administración de Empresas, de la Universidad de San Andrés, cuenta con un Diplomado en Industria 4.0. Hasta el año pasado fue presidente de la Unión Industrial Joven, organización que alcanzó durante su gestión más de 500 representantes en 17 provincias. Además, es Integrante del J6, Consejero en UIA y representante de la UIA Joven en la OIT. Integra el Instituto del diálogo interreligioso.',
    ],
    tarjeta: ideas,
    duracion: '20’ + 15’ de preguntas',
    keywords: [
      'Industria',
      'Desarrollo',
      'Tecnología',
      'Futuro',
    ],
    redes: [
      {
        logo: twitterlogo,
        texto: 'Twitter',
        url: 'https://twitter.com/tomikaragozian',
      },
      {
        logo: iglogo,
        texto: 'Instagram',
        url: 'https://www.instagram.com/tomikaragozian/',
      },
      {
        logo: LinkedInlogo,
        texto: 'LinkedIn',
        url: 'https://www.LinkedIn.com/in/tkaragozian/',
      }
    ],
    imagen: tomas,
  },

  {
    ponente: 'federico filip',
    nombre: 'Un puñado de aciertos y variopintos tropiezos de un tipo con ganas de poner en marcha ideas que le movieran la aguja a alguien.',
    shortText:
      'En esta conferencia Federico invita a la audiencia a sumergirse y bucear en las profundidades del océano de posibilidades que permite el perfil de ingeniero industrial, realizando para ello un proceso de ingeniería inversa del perfil de una persona curiosa, creativa y proactiva.',
    resumen: undefined,
    descripcionProfesional: [
      "Durante los últimos 20 años, se dedicó a facilitar herramientas y metodologías que permitieran a las personas estimular el ingenio y optimizar los procesos para generar nuevas ideas y transformarlas en resultados valiosos y exitosos.",
      "Sus intervenciones están orientadas fundamentalmente a entrenamientos individuales y en equipo, para que sean capaces de INNOVAR. Incentivándolos a su vez a afrontar nuevos desafíos, resolver problemas complejos y crear VALOR.",
      "En esta aventura ha colaborado con diversos actores entre los cuales se encuentran empresas Pymes y multinacionales, universidades, ONG´s, emprendedores e instituciones públicas.",
      "Sumado al rol de asesor, disertante y facilitador de talleres, ejerce como profesor tanto a nivel de grado como de posgrado en diferentes universidades de la región.",
      "No recuerda bien si fue algo real o lo soñó, pero cree haberse graduado de Ingeniero Industrial en la UBA y después haber hecho un Master en Innovación en la Universidad de Bologna."
  ]
  ,
    tarjeta: innovacion,
    duracion: '20’ + 15’ de preguntas',
    keywords: [
      'Creatividad',
      'Curiosidad',
      'Exploración',
      'Audacia',
      'Experimentación',
      'Emprendedores',
      'Emprender',
      'Innovación',
      'Disrupción'
  ],
    redes: [
      {
        logo: LinkedInlogo,
        texto: 'LinkedIn',
        url: 'https://www.linkedin.com/in/federico-filip-9128932a/',
      },],
    imagen: flip,
  },
];
