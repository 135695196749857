import React from 'react';
import styles from './VisitasComponent.module.css';
import { Col, Row } from 'react-bootstrap';

const VisitasComponent = ({ nombre, descripcion, imagen }) => {
  return (
    <Row className={`align-items-center justify-content-around ${styles.row}`}>
      <Col xs={10} md="auto" className={`${styles.colImg}`}>
        <img src={imagen} alt={nombre} className={styles.imagen} />
      </Col>
      <Col className={styles.colText} xs={11} lg={8}>
        <h1>{nombre}</h1>
        <p className={styles.descripcion}>{descripcion}</p>
      </Col>
    </Row>
  );
};

export default VisitasComponent;
