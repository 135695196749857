import React, { useState } from 'react';
import styles from './ConferenciasComponent.module.css';
import { Col, Row } from 'react-bootstrap';
import DatosImagen from './DatosImagen';
import DatosTexto from './DatosTexto';
const ConferenciasComponent = ({
  ponente,
  nombre,
  shortText,
  resumen,
  descripcionProfesional,
  tarjeta,
  duracion,
  keywords,
  redes,
  imagen,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div
      className={styles.wrapper}
      onClick={() => setOpen(!open)}
      aria-controls='datos-colapsables-imagen datos-colapsables-info'
      aria-expanded={open}
    >
      <Row className='d-flex justify-content-between'>
        <Col
          xs='auto'
          lg={2}
          className={`d-flex align-items-start flex-column ${styles.colImagen}`}
        >
          <div className={`${styles.bloqueImagen}`}>
            <img src={imagen} alt={nombre} className={styles.imagen} />
            <img
              src={tarjeta}
              alt='pilar'
              className={`d-lg-none ${styles.pilarMobile}`}
            />
          </div>
          <div className={`d-none d-lg-block`}>
            <DatosImagen
              open={open}
              duracion={duracion}
              keywords={keywords}
              redes={redes}
            />
          </div>
        </Col>
        <Col
          xs={12}
          lg={8}
          className={`d-flex align-items-start flex-column ${styles.colInfo}`}
        >
          <div
            className={`d-flex justify-content-center justify-content-lg-between w-100 ${styles.bloqueTitulo}`}
          >
            <div className={``}>
              <h1 className={`${styles.ponente}`}>{ponente}</h1>
              <h2 className={`${styles.nombre}`}>{nombre}</h2>
            </div>
            <div className={`d-none d-lg-block ${styles.bloqueTarjeta}`}>
              <img src={tarjeta} alt='pilar' className={styles.pilar} />
            </div>
          </div>

          <div className={styles.bloqueTexto}>
            <p>{shortText}</p>
            <DatosTexto
              open={open}
              resumen={resumen}
              descripcionProfesional={descripcionProfesional}
            />

            <div className={`d-lg-none`}>
              <DatosImagen
                open={open}
                duracion={duracion}
                keywords={keywords}
                redes={redes}
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ConferenciasComponent;
