import React from 'react'
import styles from './DatosTexto.module.css'
import { Collapse } from 'react-bootstrap'

const DatosTexto = ({open, resumen, descripcionProfesional}) => {
  return (
    <Collapse in={open}>
      <div id="datos-colapsables-info" className={styles.box}>

        {resumen && resumen.map((resumen, index) => {
          return (
            <p key={index}>{resumen}</p>
          )
        })}
        {descripcionProfesional && (
          <>
            <h2 className={styles.titulo}>Descripción profesional</h2>
            {descripcionProfesional.map((descripcion, index) => {
              return (
                <p key={index}>{descripcion}</p>
              )
            })}
          </>
        )}
      </div>
    </Collapse>
  )
}

export default DatosTexto