import React from 'react'
import styles from './Titulo.module.css'

const Titulo = ({texto}) => {
  return (
    <div className={styles.recuadro}>
      <p className={styles.texto}>{texto}</p>
    </div>
  )
}

export default Titulo