import React from 'react'
import { Collapse } from 'react-bootstrap'
import styles from './DatosImagen.module.css'
const DatosImagen = ({open, duracion, keywords, redes}) => {
  return (
    <Collapse in={open}>
      <div id="datos-colapsables-imagen" className={styles.box}>
        <h2>Duración</h2>
        <p>{duracion}</p>
        <h2>Palabras clave</h2>
        <ul>
          {keywords.map((keyword, index) => {
            return (
              <li key={index} className={styles.list}>{keyword}</li>
            )
          })}
        </ul>
        {
          redes && (
            <>
              <h2>Redes</h2>
              {redes.map((red, index) => {
                return (
                  <div className="d-flex align-items-center" key={index}>
                    <img src={red.logo} alt={red.nombre} className={styles.logo} />
                    <a href={red.url} key={index} className={styles.link}>{red.texto}</a>
                  </div>
                )
              })}
            </>
          )
        }
      </div>
    </Collapse>
  )
}

export default DatosImagen