import React from 'react';
import Titulo from '../../Titulo/Titulo';
import styles from './Visitas.module.css';
import BotonVer from '../../BotonVer/BotonVer';
import { Col, Container, Row } from 'react-bootstrap';
import pepsico from '../../../assets/images/visitas/pepsico.png';
import antares from '../../../assets/images/visitas/antares.jpeg';
import electroterma from '../../../assets/images/visitas/electroterma.jpeg';

import {alimenticio, alcohol, metalurgicas} from "../../../data/visitas"
import VisitasComponent from "../../Visitas/VisitasComponent"
import { Link } from 'react-router-dom';

const Visitas = () => {
  return (
    <div className={styles.fondo}>
      <Container>
        <Row className={styles.titulo}>
          <Col>
            <Titulo texto='Visitas Técnicas' />
          </Col>
        </Row>
        <VisitasComponent nombre={alimenticio[0].nombre} descripcion={alimenticio[0].descripcion} imagen={alimenticio[0].imagen} />
        <VisitasComponent nombre={alcohol[0].nombre} descripcion={alcohol[0].descripcion} imagen={alcohol[0].imagen} />
        <VisitasComponent nombre={metalurgicas[2].nombre} descripcion={metalurgicas[2].descripcion} imagen={metalurgicas[2].imagen} />

        <Row>
          <Col className={styles.cajaBoton}>
            <Link to='/visitas_tecnicas' className={styles.link}>
              <BotonVer texto="Ver todos"/>
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Visitas;
