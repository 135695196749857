import React from 'react'
import styles from "./Footer.module.css"
import logo from "../../assets/footer/logo_caeii.png"
import openInNew from "../../assets/icons/openInNew.svg"
import googlePlay from "../../assets/footer/googlePlay.png"
import appStore from "../../assets/footer/appStore.png"
import {BsInstagram} from "react-icons/bs"
import { Link } from 'react-router-dom'
const Footer = () => {
  return ( 
  <footer className=" text-center" style={{backgroundColor:'#D85120', marginTop:'50px', padding:'20px'}} >
      <div className="row justify-content-center ">
      
        <div className="col-lg-3 mb-lg-0 mb-3" >
        <img src={logo} alt="logo" className={styles.logo} />
          <div className="row ">
            <div className='d-flex justify-content-center' style={{gap:'40px'}}>
              <Link to="https://www.instagram.com/aareii.mdp/" target="_blank" rel="noreferrer" className={styles.cajaTextoLogo} >
                <BsInstagram className={styles.instagram} />
                <p className={styles.textoInstagram}>Instagram</p>
              </Link>
              <Link to="https://www.aareii.org.ar/" target="_blank" rel="noreferrer" className={styles.cajaTextoLogo}>
                <img src={openInNew} alt="openInNew" className={styles.openInNew} />
                <p className={styles.textoAAREII}>AAREII</p>
              </Link>
            </div>          
          </div>
        </div>

        <div className="col-md-3 mb-md-0 mb-3 text-start" style={{marginLeft:"10px"}}>
          <h1 className='text-light' style={{borderBottom:"1px solid white", width:"60%", marginLeft:"33px", marginBottom:"15px"}}>Secciones</h1>
          <ul style={{listStyle: "none"}}>
            <Link to="/" style={{color:"white", textDecoration:"none"}}><li className='text-light icon-link-hover'>Home</li></Link>
            <Link to="/pilares" style={{color:"white", textDecoration:"none"}}><li className='text-light '>Pilares</li></Link>
            <Link to="/conferencias" style={{color:"white", textDecoration:"none"}}><li className='text-light '>Conferencias</li></Link>
            <Link to="/talleres" style={{color:"white", textDecoration:"none"}}><li className='text-light '>Talleres</li></Link>
            <Link to="/visitas_tecnicas" style={{color:"white", textDecoration:"none"}}><li className='text-light '>Visitas Tecnicas</li></Link>
          </ul>
        </div>

        <div className="col-md-3 mb-md-0 mb-3 text-start">
          <h1 className='text-light' style={{borderBottom:"1px solid white", width:"60%", marginLeft:"33px", marginBottom:"15px"}}>Descargas</h1>
          <ul className='col-md-3'>
            <Link to={"https://apps.apple.com/app/caeii-2023/id6461119129"}><img src={appStore} alt="appStore" className={styles.appDownload} /></Link>
            <Link to={"https://play.google.com/store/apps/details?id=com.caeii2023app"}><img src={googlePlay} alt="googlePlay" className={styles.appDownload} /></Link>
          </ul>
        </div>

      </div>
  </footer>
  )
}
export default Footer