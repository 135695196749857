import React from 'react';
import styles from './Conferencias.module.css';
import imagenFlip from '../../../assets/images/conferencias/flip.png';
import imagenSerna from '../../../assets/images/conferencias/serna.png';
import imagenVivas from '../../../assets/images/conferencias/vivas.png';
import Titulo from '../../Titulo/Titulo.jsx';
import BotonVer from '../../BotonVer/BotonVer.jsx';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ponentes = [
  {
    nombre: 'Juan Martín de la Serna',
    imagen: imagenSerna,
    texto:
      'Es el actual Presidente de la operación de Mercado Libre Argentina, cargo que ya había ocupado desde 2004 a 2012. Es reconocido por su liderazgo en el desarrollo y expansión de la plataforma de comercio electrónico más grande de América Latina, impulsando el crecimiento del comercio digital en la región.',
  },
  {
    nombre: 'Fredi Vivas',
    imagen: imagenVivas,
    texto:
      'Fredi Co-fundó Rocking Data, una startup destacada en Latinoamérica y reconocida como una de las más destacadas en cuanto a proyección. Trabaja para democratizar la ciencia de datos y la inteligencia artificial, con clientes como MercadoLibre y YPF. Es profesor en la Universidad de San Andrés.',
  },
  {
    nombre: 'Federico Filip',
    imagen: imagenFlip,
    texto:
      'Se dedica a la gestión de la innovación. Colabora con organizaciones para desarrollar las habilidades de las personas y estimular la generación de nuevas ideas. Además, es profesor y facilitador de capacitaciones en temas relacionados con la creatividad y la innovación.',
  },
]

const Conferencias = () => {
  return (
    <div className={styles.wrapper}>
      <Container>
        <Row className={styles.titulo}>
          <Col>
            <Titulo texto='Conferencias' />
          </Col>
        </Row>
          {ponentes.map((ponente, index) => (
            <Row className={`align-items-center justify-content-around ${styles.cajaTarjeta}`}>
              <Col key={index} className={styles.cajaPonente}xs={8} md="auto">
                <img
                  src={ponente.imagen}
                  alt={ponente.nombre}
                  className={styles.imagen}
                />
              </Col >
              <Col className={styles.cajaTexto} xs={9}>

                  <h3 >{ponente.nombre}</h3>
                  <p className={styles.texto}>{ponente.texto}</p>
              </Col>
            </Row>
          ))}
        <Row>
          <Link to='/conferencias' className={styles.cajaBoton}>
            <BotonVer texto='Ver todos' />
          </Link>
        </Row>
      </Container>
    </div>
  );
};

export default Conferencias;
