import React from 'react'
import styles from './BotonVer.module.css'
//importamos arrow outward
import arrowOutward from '../../assets/icons/arrowOutward.svg'
const BotonVerMas = ({texto}) => {
  return (
    <div className={styles.caja}>
      <p className={styles.texto}>{texto}</p>
      <img src={arrowOutward} alt="arrowOutward" className={styles.arrowOutward}/>
    </div>
  )
}

export default BotonVerMas