import React from 'react'
import Titulo from '../Titulo/Titulo'
import { Container } from 'react-bootstrap'
import styles from "./Visitas.module.css"
import {alimenticio, alcohol, metalurgicas, pesqueras, quimicas, servicios} from '../../data/visitas'
import VisitasComponent from './VisitasComponent'

const Visitas = () => {
  return (
    <div className={styles.fondo}>
      <Container>
        <div className={styles.tituloGrande}>
          <Titulo texto="Visitas técnicas" />
        </div>
        <h1 className={styles.titulo}>Rubro Alimenticio</h1>
        {
          alimenticio.map((item, index) => (
            <VisitasComponent
              key={index+100}
              nombre={item.nombre}
              descripcion={item.descripcion}
              imagen={item.imagen}
            />
          ))
        }
        <h1 className={styles.titulo}>Rubro Alcohol</h1>
        {
          alcohol.map((item, index) => (
            <VisitasComponent
              key={index+200}
              nombre={item.nombre}
              descripcion={item.descripcion}
              imagen={item.imagen}
            />
          ))
        }
        <h1 className={styles.titulo}>Rubro Metalúrgicas</h1>
        {
          metalurgicas.map((item, index) => (
            <VisitasComponent
              key={index+300}
              nombre={item.nombre}
              descripcion={item.descripcion}
              imagen={item.imagen}
            />
          ))
        }
        <h1 className={styles.titulo}>Rubro Pesqueras</h1>
        {
          pesqueras.map((item, index) => (
            <VisitasComponent
              key={index+400}
              nombre={item.nombre}
              descripcion={item.descripcion}
              imagen={item.imagen}
            />
          ))
        }
        <h1 className={styles.titulo}>Rubro Químicas</h1>
        {
          quimicas.map((item, index) => (
            <VisitasComponent
              key={index+500}
              nombre={item.nombre}
              descripcion={item.descripcion}
              imagen={item.imagen}
            />
          ))
        }
        <h1 className={styles.titulo}>Rubro Servicios</h1>
        {
          servicios.map((item, index) => (
            <VisitasComponent
              key={index+600}
              nombre={item.nombre}
              descripcion={item.descripcion}
              imagen={item.imagen}
            />
          ))
        }

      </Container>
    </div>
  )
}

export default Visitas