import React from 'react';
import "./styles/fonts.css" // Importando fuentes personalizadas 
import './styles/globals.css' // Importando colores principales 
import CustomNavbar from './Components/CustomNavbar/CustomNavbar';
import Home from './Components/Home/Home';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PilaresPage from './Components/PilaresPage/PilaresPage';
import Conferencias from './Components/Conferencias/Conferencias';
import ScrollToTop from './Components/Scripts/ScrollToTop'; // Importando script para que la página se posicione en la parte superior al cambiar de ruta
import './scss/custom-bootstrap.scss'; // Importando Bootstrap personalizado
import Footer from './Components/Footer/Footer';
import Visitas from './Components/Visitas/Visitas';
import Talleres from './Components/Talleres/Talleres';
import NotFound from './Components/NotFound/NotFound';


function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <CustomNavbar />
      <Routes>
        
        <Route path={"/"} element={<Home />} />
        <Route path={"/pilares"} element={<PilaresPage />} />
        <Route path={"/conferencias"} element={<Conferencias />} />
        <Route path={"/talleres"} element={<Talleres />} />
        <Route path={"/visitas_tecnicas"} element={<Visitas />} />
        <Route path={"*"} element={<NotFound />} /> 
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
