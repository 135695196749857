import React from 'react';
import styles from './PilaresPage.module.css';
import { Col, Container, Row } from 'react-bootstrap';
import pilarIdeas from '../../assets/images/tarjetas/ideas.png';
import pilarRevoluciones from '../../assets/images/tarjetas/revoluciones.png';
import pilarInnovacoin from '../../assets/images/tarjetas/innovacion.png';
import pilarImpacto from '../../assets/images/tarjetas/impacto.png';
import Titulo from '../Titulo/Titulo.jsx';

const PilaresPage = () => {
  return (
    <Container>
      <Titulo texto='Pilares' />

      <Row className={`d-flex justify-content-around align-items-center ${styles.tarjeta}`}>
        <Col xs='auto'>
          <img className={styles.imagen} src={pilarIdeas} alt='ideas' />
        </Col>
        <Col xs={10} md={8} className="d-flex justify-content-center">
          <p className={styles.texto}>
            ¿Cómo pensamos? ¿Cuál es el costo de elegir, de tomar una decisión y
            no la otra? ¿Qué pasa en nuestra cabeza? En este capítulo, se busca
            comprender cómo pensamos y tomamos decisiones, y buscamos comprender
            cual es el proceso de razonamiento particular que distingue a los
            ingenieros. Comprender nuestra forma de pensar y de comportarnos nos
            permite entender nuestro estado actual y comenzar a planificar el
            futuro. Existen numerosos referentes que nos instan a tener el
            coraje de tomar acciones en el presente.
          </p>
        </Col>
      </Row>

      <Row className={`d-flex justify-content-around align-items-center ${styles.tarjeta}`}>
        <Col xs='auto'>
          <img
            className={styles.imagen}
            src={pilarRevoluciones}
            alt='revoluciones'
          />
        </Col>
        <Col xs={10} md={8} className="d-flex justify-content-center">
          <p className={styles.texto}>
            El avance de la tecnología es exponencial, no así la capacidad del
            ser humano de adaptarse a ello. ¿O tal vez si? Te invitamos a
            conocer más sobre la industria 4.0 y sobre los desafíos con los que
            se encuentran las organizaciones para gestionar las tecnologías
            emergentes.
          </p>
        </Col>
      </Row>
      <Row className={`d-flex justify-content-around align-items-center ${styles.tarjeta}`}>
        <Col xs='auto'>
          <img className={styles.imagen} src={pilarImpacto} alt='impacto' />
        </Col>
        <Col xs={10} md={8} className="d-flex justify-content-center flex-column">
          <p className={styles.texto}>
            ¿Qué clase de profesional querés ser? ¿Alguna vez pensaste en que el objetivo no era el beneficio económico? Las organizaciones hoy  intentan salvar el mundo teniendo en cuenta el impacto económico, ambiental, social y tecnológico de sus acciones. <br /> Entérate cómo en este pilar.</p>
        </Col>
      </Row>
      <Row className={`d-flex justify-content-around align-items-center ${styles.tarjeta}`}>
        <Col xs='auto'>
          <img
            className={styles.imagen}
            src={pilarInnovacoin}
            alt='innovacion'
          />
        </Col>
        <Col xs={10} md={8} className="d-flex justify-content-center flex-column">
          <p className={styles.texto}>
            ¿Por qué estudiamos ingeniería industrial? ¿Cuál es el código genético que nos une? Si es que existiera claro. <br />
            Innovación y futuro navega sobre las oportunidades más disruptivas que podemos encontrar. <br/> Incluso la de emprender: emprender por tu cuenta o emprender dentro de organizaciones. Genera tu propio camino.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default PilaresPage;
