import { Link } from "react-router-dom";

const NotFound = () => {
    return(
            <div className="container d-flex align-items-center justify-content-center" style={{height: "60vh", flexDirection:"column"}}>  
                <h1>404</h1>
                <p>La página no fue encontrada</p>
                <Link to={'/'} >Volver a inicio</Link>
            </div>
        );
};

export default NotFound;