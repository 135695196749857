import pepsico from '../assets/visitas/pepsico.png';
import Pharmamerican from '../assets/visitas/Pharmamerican.png';
import panacity from '../assets/visitas/panacity.png';
import frio from '../assets/visitas/frio.png';

import antares from '../assets/visitas/antares.png';
import kalmar from '../assets/visitas/kalmar.png';
import costaYPampa from '../assets/visitas/costaYPampa.png';
import baum from '../assets/visitas/baum.png';

import coppens from '../assets/visitas/coppens.png';
import dpi from '../assets/visitas/dpi.png';
import electroterma from '../assets/visitas/electroterma.png';

import solimeno from '../assets/visitas/solimeno.png';
import moscuzza from '../assets/visitas/moscuzza.png';
import moscuzzaRedes from '../assets/visitas/moscuzzaRedes.png';

import norgreen from '../assets/visitas/norgreen.png';

import obrasSanitarias from '../assets/visitas/obrasSanitarias.png';
import globant from '../assets/visitas/globant.png';

export const alimenticio = [
  {
    nombre: "Pepsico de Argentina S.R.L.",
    descripcion: "Te invitamos a explorar la planta de Pepsico en Mar del Plata, una de las más importantes en la producción de snacks. Durante la visita, conocerás de cerca los equipos y tecnologías utilizadas en la manufactura, y cómo la ingeniería industrial es clave para el éxito de esta reconocida empresa en la industria alimenticia.",
    imagen: pepsico
  },
  {
    nombre: "Laboratorios Pharmamerican S.R.L.",
    descripcion: "Pharmamerican te abrirá las puertas de su moderna planta farmacéutica, donde podrás aprender sobre los rigurosos procesos de manufactura y cómo la ingeniería industrial garantiza la seguridad y eficacia de los productos farmacéuticos. Pharmamerican te abrirá las puertas de su moderna planta manufacturera especializada en el desarrollo y la fabricación de productos para el cuidado de la salud. Dentro de su cartera de productos cuenta con suplementos nutricionales, medicamentos herbarios e infusiones premium a base de hierbas y frutas en saquitos, diseñadas para los paladares más exigentes.",
    imagen: Pharmamerican
  },
  {
    nombre: "Panacity Mdq SRL",
    descripcion: "Panacity es una reconocida fábrica marplatense que elabora panes de molde y budines estilo artesanal.",
    imagen: panacity
  },
  {
    nombre: "Frio Polar S.A.",
    descripcion: "Frío Polar S.A. es una empresa familiar marplatense que centra su actividad en el desarrollo de soluciones integrales para el almacenaje y las operaciones logísticas de productos súper congelados.",
    imagen: frio
  }
];

export const alcohol = [
  {
    nombre: "antares",
    descripcion: "Cervecería Antares es una destacada empresa de cerveza artesanal fundada en Mar del Plata, Argentina, en 1998. Se ha convertido en una de las principales referencias en la industria cervecera artesanal de Argentina y ha expandido su presencia en varias ciudades del país. La planta de producción de Cervecería Antares utiliza equipos modernos y técnicas artesanales para elaborar sus cervezas.",
    imagen: antares
  },
  {
    nombre: "Kalmar",
    descripcion: "Kalmar es la primera destilería de bebidas blancas de Mar del Plata. Operan como una destilería colaborativa, y generando así el encuentro de maestros destileros, bartenders y chefs. Fomentan la economía circular para lograr un impacto en la comunidad a través de la articulación con organizaciones sociales.",
    imagen: kalmar
  },
  {
    nombre: "Costa y Pampa",
    descripcion: "Costa y Pampa se especializa en la producción de vinos de alta calidad, aprovechando las condiciones climáticas y los terrenos únicos de la región. La bodega combina la tradición vitivinícola con técnicas modernas de vinificación para crear vinos con carácter y personalidad. Sus vinos reflejan el espíritu de la costa atlántica argentina, ofreciendo una experiencia única en cada copa. Al visitar Costa y Pampa, los entusiastas del vino tienen la oportunidad de conocer de cerca el proceso de producción, desde la vendimia hasta el envejecimiento en barricas de roble.",
    imagen: costaYPampa
  },
  {
    nombre: "Baum",
    descripcion: "Cervecería Baum es una reconocida cervecería artesanal ubicada en la ciudad de Mar del Plata, Argentina.  Durante esta experiencia única, podrás adentrarte en el fascinante mundo de la cerveza artesanal y conocer de cerca el proceso de elaboración.",
    imagen: baum
  }
];

export const metalurgicas = [
  {
    nombre: "Coppens S.A.",
    descripcion: "Coppens cuenta con una planta modelo de más de 12.000 mts2, emplea a más de 100 personas y adquiriere de forma constante y progresiva tecnología y nuevas herramientas que permiten su producción de termotanques y calefactores. Es una empresa certificada por la ISO 9001 y con un gran enfoque a la calidad y la mejora continua.",
    imagen: coppens
  },
  {
    nombre: "DPI - Del Plata Ingenieria",
    descripcion: "Del plata Ingeniería es una empresa dedicada a brindar servicios, ejecutar obras y desarrollar productos para satisfacer a sus clientes en las industrias: gas y petróleo, energía, naval, alimenticia y salud. Diseñan y fabrican productos y servicios que mejoran la productividad de los procesos y la seguridad de las personas.",
    imagen: dpi
  },
  {
    nombre: "Electroterma Mar del Plata S.R.L.",
    descripcion: "Electroterma es una empresa familiar marplatense moderna y dinámica que trabaja día a día para ofrecer soluciones en termomecánica en todo el país.",
    imagen: electroterma
  }
];

export const pesqueras = [
  {
    nombre: "Solimeno S.A.",
    descripcion: "Solimeno es una de las empresas pesqueras más grandes del país, que emplea a más de 850 personas y comercializa anualmente más de 30.000 toneladas tanto en mercado interno como exportaciones. Hoy en día la empresa se esta diversificando no solo en al pesca sino en el rubro alimenticio con sus líneas de empanados y congelados",
    imagen: solimeno
  },
  {
    nombre: "Moscuzza",
    descripcion: "El Grupo Moscuzza, uno de los tradicionales pioneros del sector pesquero industrial argentino, desarrolla con recursos humanos y materiales propios, la totalidad de sus funciones de extracción, procesado e industrialización, comercialización y distribución de frutos de mar, tanto frescos como congelados.",
    imagen: moscuzza
  },
  {
    nombre: "Moscuzza redes",
    descripcion: "Moscuzza, Artes de Pesca, es una Compañía industrial. Fabricante y proveedor desde hace más de 72 años de la industria pesquera, naval y agrícola.",
    imagen: moscuzzaRedes
  }
];

export const quimicas = [
  {
    nombre: "Norgreen S.A.",
    descripcion: "Fundada en el año 1995, Norgreen S.A. es una empresa líder en la elaboración de productos farmacéuticos inyectables. Sus plantas productivas están equipadas con moderna tecnología para la fabricación de mas de 50 medicamentos hospitalarios, siguiendo estrictos procedimientos para garantizar la asepsia de nuestros productos.",
    imagen: norgreen
  }
];

export const servicios = [
  {
    nombre: "OSSE - Obras Sanitarias S.E. MGP",
    descripcion: "",
    imagen: obrasSanitarias
  },
  {
    nombre: "E.D.AR. - Estación Depuradora de Aguas Residuales",
    descripcion: "La EDAR (Estación Depuradora de Aguas Residuales) de Mar del Plata es una infraestructura esencial para el tratamiento de aguas residuales en la ciudad. Ubicada estratégicamente, su función es purificar las aguas antes de ser vertidas al ambiente, contribuyendo así a la protección del medio ambiente y la conservación de los recursos hídricos locales. La EDAR juega un papel crucial en el saneamiento y la preservación del ecosistema marplatense.",
    imagen: obrasSanitarias
  },
  {
    nombre: "Centro de Abastecimiento de Agua",
    descripcion: "El centro de abastecimiento de agua en Mar del Plata es una infraestructura vital que asegura el suministro de agua potable a la ciudad. Utilizando tecnología avanzada y fuentes naturales como el Acuífero Puelche, garantiza el acceso a agua segura y de calidad para satisfacer las necesidades de los residentes y turistas de la ciudad. ",
    imagen: obrasSanitarias,
  },
  {
    nombre: "Globant",
    descripcion: "Globant es una destacada empresa de servicios de tecnología y consultoría digital, con presencia a nivel mundial. Con un enfoque en la innovación y la transformación digital, esta empresa se destaca por ofrecer soluciones de vanguardia para clientes de diversas industrias en todo el mundo. Globant cuenta con un talentoso equipo de profesionales en ingeniería, diseño y estrategia digital, quienes trabajan de manera colaborativa para crear experiencias digitales únicas y soluciones personalizadas para cada cliente",
    imagen: globant,
  },
]