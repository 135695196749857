import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './Bienvenida.module.css';
import logoPrincipalCAEII from '../../../assets/images/mainLogo.png';

const Bienvenida = () => {
  return (
    <Container className={styles.wrapper}>
      <Row className="d-flex align-items-center justify-content-around">
        <Col className='d-flex flex-column align-items-center justify-content-center' lg="6">
          <img
            src={logoPrincipalCAEII}
            alt='Logo Principal CAEII'
            className={styles.imagen}
          />
          <h1 className={styles.textoLogo}>
            24 al 27 de Agosto - mar del plata
          </h1>
        </Col>
        <Col className={`${styles.columnaTexto}` } xs="8" md="6" lg="5">
          <p className={styles.textoPrincipal}>
            Congreso Argentino de Estudiantes de Ingenería Industrial y
            carreras afines
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Bienvenida;
