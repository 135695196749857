import React from 'react'
import styles from './SeparadorPrincipal.module.css'

const SeparadorPrincipal = ({altoDelSeparador = '42px'}) => {
  return (
    <div className={styles.cajaDelSeparador} style={{
      height: altoDelSeparador
    }}>
        <div className={styles.bloque1}></div>
        <div className={styles.bloque2}></div>
        <div className={styles.bloque3}></div>
        <div className={styles.bloque4}></div>
      </div>
  )
}

export default SeparadorPrincipal