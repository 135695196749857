import React, { useState } from 'react';
import styles from './TalleresComponent.module.css';
import { Col, Row } from 'react-bootstrap';
import BotonVer from '../BotonVer/BotonVer';
import Collapse from 'react-bootstrap/Collapse';

const TalleresComponent = ({
  titulo,
  subtitulo,
  imagen,
  shortText,
  longText,
  organizador,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={styles.wrapper}
            onClick={() => setOpen(!open)}
            aria-controls='example-collapse-text'
            aria-expanded={open}>

      <Row className="d-flex justify-content-between">
        <Col className={`d-flex align-items-start ${styles.colImagen}`}>
          <img src={imagen} alt={titulo} className={styles.imagen} />
          <img
              src={organizador}
              alt='organizador'
              className={`d-lg-none ${styles.organizadorMobile}`}
            />
        </Col>
        <Col md={8} xl={9} className={styles.colText}>
          <div className="d-flex justify-content-between">
            <div className={styles.cajaTextoTitulo}>
              <h1 className={styles.titulo}>{titulo}</h1>
              <p className={styles.subtitulo}>{subtitulo}</p>
            </div>
            <img
              src={organizador}
              alt='organizador'
              className={`d-none d-lg-flex ${styles.organizador}`}
            />
          </div>
          <p className={styles.texto}>{shortText}</p>
          <Collapse in={open}>
            <div id="example-collapse-text">
              {longText.map((parrafo, index) => {
                return (
                  <p key={index}
                    className={styles.texto}>
                    {parrafo}
                  </p>
                );
              }, [])}
            </div>
          </Collapse>
          <div>
            {
              open ? (
                <p className={styles.button}>Ver menos</p>
              ) : (
                <p className={styles.button}>Ver más</p>
              )
              // <img src={arrowOutward} alt="arrowOutward" className={styles.arrowOutward}/>
            }
            
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TalleresComponent;
