import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './Pilares.module.css';

import pilarIdeas from '../../../assets/images/tarjetas/ideas.png';
import pilarRevoluciones from '../../../assets/images/tarjetas/revoluciones.png';
import pilarInnovacoin from '../../../assets/images/tarjetas/innovacion.png';
import pilarImpacto from '../../../assets/images/tarjetas/impacto.png';

import BotonVer from '../../BotonVer/BotonVer';
import { Link } from 'react-router-dom';
import Titulo from '../../Titulo/Titulo';


const Pilares = () => {
  return (
    <>
      <Container>

        <Titulo texto='Pilares' />

        <Row className="d-flex justify-content-center align-items-center">
          <Col xs={12} md={6} xl="auto" className="text-center">
            <img className={styles.imagen} src={pilarIdeas} alt='ideas' />
          </Col>
          <Col xs={12} md={6} xl="auto" className="text-center">
            <img className={styles.imagen} src={pilarRevoluciones} alt='revoluciones'/>
          </Col>
          <Col xs={12} md={6} xl="auto" className="text-center">
            <img className={styles.imagen} src={pilarImpacto} alt='impacto' />
          </Col>
          <Col xs={12} md={6} xl="auto" className="text-center">
            <img className={styles.imagen} src={pilarInnovacoin} alt='innovacion'/>
          </Col>
        </Row>
        <div className={styles.cajaBoton} as={Link} to={'/pilares'}>
          <Link to='/pilares' className={styles.link}>
            <BotonVer texto="Ver más"/>
          </Link>
        </div>
      </Container>
    </>
  );
};

export default Pilares;
