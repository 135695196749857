import macro from "../assets/images/sponsor/macro.png"
import neural from "../assets/images/sponsor/neural.png"
import panAmerican from "../assets/images/sponsor/panAmerican.png"
import techint from "../assets/images/sponsor/techint.png"
import raizen from "../assets/images/sponsor/raizen.png"
import braum from "../assets/images/sponsor/braum.png"
import pepsico from "../assets/images/sponsor/pepsico.png"
import osde from "../assets/images/sponsor/osde.png"
import mardelplata from "../assets/images/sponsor/mardelplata.jpg"
import hergo from "../assets/images/sponsor/hergo.png"

export const sponsor = {
  macro,
  neural,
  panAmerican,
  techint,
  raizen,
  braum,
  pepsico,
  hergo,
  mardelplata,
  osde
}