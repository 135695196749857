import React from 'react'
import Titulo from '../Titulo/Titulo'
import styles from './Conferencias.module.css'
import ConferenciasComponent from './ConferenciasComponent'
import { conferencias } from '../../data/conferencias'
import { Container } from 'react-bootstrap'

const Conferencias = () => {
  return (
    <div className={styles.wrapper}>
      <Container>
        <div className={styles.titulo}>
          <Titulo texto="Conferencias" />
        </div>
        <div className={styles.conferencias}>
          {conferencias.map((conferencia, index) => (
            <ConferenciasComponent
              key={index}
              ponente={conferencia.ponente}
              nombre={conferencia.nombre}
              shortText={conferencia.shortText}
              resumen={conferencia.resumen}
              descripcionProfesional={conferencia.descripcionProfesional}
              tarjeta={conferencia.tarjeta}
              duracion={conferencia.duracion}
              keywords={conferencia.keywords}
              redes={conferencia.redes}
              imagen={conferencia.imagen}
            />
          ))}
        </div>

      </Container>
    </div>
  )
}

export default Conferencias