import React from 'react'
import styles from './Talleres.module.css'
import TalleresComponent from './TalleresComponent'
import { Container } from 'react-bootstrap'
import {talleres} from '../../data/talleres'
import Titulo from '../Titulo/Titulo'
const Talleres = () => {
  return (
    <div className={styles.wrapper}>
      <Container>
        <Titulo texto="Talleres" />
        {
          talleres.map((taller, index) => {
            return (
              <TalleresComponent
                key={index}
                titulo={taller.titulo}
                subtitulo={taller.subtitulo}
                imagen={taller.imagen}
                shortText={taller.shortText}
                longText={taller.longText}
                hashtags={taller.hashtags}
                organizador={taller.organizador}
              />
            )
          })
        }
      </Container>
    </div>
  )
}

export default Talleres