import React from 'react'
import styles from './Nosotros.module.css'
import { sponsor } from '../../../data/sponsor'
import Titulo from '../../Titulo/Titulo'
import { Col, Container, Row } from 'react-bootstrap'

const Nosotros = () => {
  return (
    <Container>

      <Titulo texto="Nosotros" />

      <Row className="d-flex text-center justify-content-center">
        <Col xs={11}>
          <p>El Congreso Argentino de Estudiantes de Ingeniería Industrial y carreras afines (CAEII) es el evento más importante de AArEII.
          Tiene una duración de cuatro días, donde se disfrutan y aprovechan diversas actividades académicas. El mismo tiene lugar en el mes de agosto de cada año y cuenta con una asistencia de 1500 estudiantes aproximadamente.</p>
        </Col>
      </Row>
      <Row className={styles.titulo}>
        <Col>
          <Titulo texto="Empresas que nos acompañan" />
        </Col>
      </Row>

      <Row className="align-items-center justify-content-center">
        <Col xs="auto">
          <img src={sponsor.macro} alt="macro" className={styles.sponsor} />
        </Col>
        <Col xs="auto">
          <img src={sponsor.neural} alt="neural" className={styles.sponsor} />
        </Col>
      </Row>
      <div className={styles.barra}></div>
      <Row className="align-items-center justify-content-center">
        <Col xs="auto">
          <img src={sponsor.panAmerican} alt="panAmerican" className={styles.sponsor} />
        </Col>
        <Col xs="auto">
          <img src={sponsor.techint} alt="techint" className={styles.sponsor} />
        </Col>
        <Col xs="auto">
          <img src={sponsor.raizen} alt="raizen" className={styles.sponsor} />
        </Col>
        <Col xs="auto">
          <img src={sponsor.braum} alt="braum" className={styles.sponsor} />
        </Col>
        <Col xs="auto">
          <img src={sponsor.pepsico} alt="pepsico" className={styles.sponsor} />
        </Col>
        <Col xs="auto">
          <img src={sponsor.hergo} alt="hergo" className={styles.sponsor} />
        </Col>
      </Row>
      <div className={styles.barra}></div>
      <Row className="align-items-center justify-content-center">
        <Col xs="auto">
          <img src={sponsor.osde} alt="osde" className={styles.sponsor} />
        </Col>
        <Col xs="auto">
          <img src={sponsor.mardelplata} alt="mardelplata" className={styles.sponsor} />
        </Col>
      </Row>
    </Container>
  )
}

export default Nosotros