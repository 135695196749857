import imgpistas from '../assets/talleres/imgpistas.png';
import orgpistas from '../assets/talleres/orgpistas.png';
import imgjust from '../assets/talleres/imgjust.png';
import orgjust from '../assets/talleres/orgjust.png';
import imggimnasio from '../assets/talleres/imggimnasio.png';
import orggimnasio from '../assets/talleres/orggimnasio.png';
import imgequipos from '../assets/talleres/imgequipos.png';
import orgequipos from '../assets/talleres/orgequipos.png';
import imgnegociacion from '../assets/talleres/imgnegociacion.png';
import orgnegociacion from '../assets/talleres/orgnegociacion.png';
import imgsimulacro from '../assets/talleres/imgsimulacro.png';
import orgsimulacro from '../assets/talleres/orgsimulacro.png';
import imgcuando from '../assets/talleres/imgcuando.png';
import orgcuando from '../assets/talleres/orgcuando.png';
import imgconstruyendo from '../assets/talleres/imgconstruyendo.png';
import orgconstruyendo from '../assets/talleres/orgconstruyendo.png';
import imgdominando from '../assets/talleres/imgdominando.png';
import orgdominando from '../assets/talleres/orgdominando.png';
import imghomosapiens from '../assets/talleres/imghomosapiens.png';
import orghomosapiens from '../assets/talleres/orghomosapiens.png';
import imginteligencia from '../assets/talleres/imginteligencia.png';
import orginteligencia from '../assets/talleres/orginteligencia.png';
import imgjuguemos from '../assets/talleres/imgjuguemos.png';
import orgjuguemos from '../assets/talleres/orgjuguemos.png';
import imgingenierilab from '../assets/talleres/imgingenierilab.png';
import orgingenierilab from '../assets/talleres/orgingenierilab.png';
import imglinkedin from '../assets/talleres/imglinkedin.png';
import orglinkedin from '../assets/talleres/orglinkedin.png';
import imgalritmo from '../assets/talleres/imgalritmo.png';
import orgalritmo from '../assets/talleres/orgalritmo.png';

export const talleres = [
  {
    titulo: 'de las pistas a las patentes',
    subtitulo: '¡ convertite en ingeniero de la fórmula 1 !',
    shortText:
      'A medida que jugamos, aprendemos. ¿Qué tal si te convertís en empresario u operario por un rato? Simulemos la operatoria del comercio exterior la producción hasta la entrega del producto terminado, y tal vez resultes!',
    longText: [
      'A través de un juego de construcción de automóviles de juguete utilizando materiales simples, los estudiantes de ingeniería tendrán la oportunidad de aplicar su creatividad e ingenio para diseñar y construir sus propios autos de carreras.',
      'A medida que los estudiantes diseñan y construyen sus propios autos, podrán experimentar directamente los desafíos y decisiones que los ingenieros enfrentan al crear nuevas tecnologías. Alentándolos a pensar en soluciones innovadoras y se les anima a considerar la importancia de proteger sus ideas mediante de invención y las oportunidades que generan las patentes en el proceso',
      '#PropiedadIntelectual #Competencia #Creatividad #Trabajoenequipo',
    ],
    imagen: imgpistas,
    organizador: orgpistas,
  },
  {
    titulo: 'Just in time en acción',
    subtitulo: 'stock vs flujo',
    shortText:
      'Exploremos el apasionante mundo del Sistema de Producción Toyota, cuna de ideas que revolucionaron la gestión de la producción del siglo pasado, y siguen vigentes hasta el día de hoy.',
    longText: [
      'Simulando un proceso productivo sencillo, te invitamos a explorar en primera persona cómo el Just in Time, los sistemas Pull y las Tarjetas Kanban te ayudarán a aumentar la productividad en entornos de reales.',
      '#Lean #Kanban #JustInTime #Toyota #Sistema Pull',
    ],
    imagen: imgjust,
    organizador: orgjust,
  },
  {
    titulo: 'Gimnasio de innovación',
    subtitulo: 'Fortaleciendo tus músculos creativos',
    shortText:
      'Durante el encuentro, los participantes descubrirán y se familiarizarán con validadas para desarrollar y entrenar la habilidad creativa.',
    longText: [
      'Una vez concluido el taller, serán capaces de diseñar y realizar de manera autónoma, sus rutinas de entrenamiento para potenciar su perfil profesional.',
      '#Creatividad #Innovación #Disrupción #HabilidadesBlandas #Ideación #Ingenio',
    ],
    imagen: imggimnasio,
    organizador: orggimnasio,
  },
  {
    titulo: 'Equipos de Alto Rendimiento',
    subtitulo: 'Argentina brilla en Qatar',
    shortText:
      'Durante el encuentro, los participantes descubrirán y se familiarizarán con técnicas validadas para desarrollar y entrenar la habilidad creativa.',
    longText: [
      'Una vez el taller, serán capaces de diseñar y realizar de manera autónoma, sus rutinas de entrenamiento para potenciar su perfil profesional.',
      '#Creatividad #Innovación #Disrupción #HabilidadesBlandas #Ideación #Ingenio',
    ],
    imagen: imgequipos,
    organizador: orgequipos,
  },
  {
    titulo: 'Negociación Maestra',
    subtitulo: 'Desarrolla tus habilidades para el éxito comercial',
    shortText:
      'En este taller, aprenderás estrategias de negociación y persuasión, adaptadas al de la industria.',
    longText: [
      'Se abordarán fundamentos de la negociación, técnicas , escenarios específicos y la importancia de construir relaciones sólidas a largo plazo. Mediante ejemplos y prácticas efectivas, los desarrollarán sus habilidades de negociación y confianza para con éxito los desafíos comerciales y alcanzar acuerdos beneficiosos para futuras carreras',
      '#HabilidadesComerciales #Negociación #Experiencia #Cliente #Confianza #TécnicasDeVentas #RelacionesEstratégicas',
    ],
    imagen: imgnegociacion,
    organizador: orgnegociacion,
  },
  {
    titulo: 'SIMULACRO DE GESTIÓN PÚBLICA',
    subtitulo: 'Construyendo el equipo del cambio',
    shortText:
      'Ha llegado el momento de elegir a los miembros que acompañarán al presidente el Poder Ejecutivo, trabajando codo a codo para cumplir los objetivos que se propuesto en la campaña electoral.',
    longText: [
      'En este simulacro serás uno de los tantos que debatirá y pondrá a prueba sus habilidades, para demostrar que ser seleccionado como miembro del futuro Ministerio de Economía de la .',
      '#Debate #Oratoria #Persuasión #ConocimientoGeneral',
    ],
    imagen: imgsimulacro,
    organizador: orgsimulacro,
  },
  {
    titulo: '¡JUGUEMOS A EXPORTAR!',
    subtitulo: 'Descubriendo los caminos del comercio internacional',
    shortText:
      'A medida que jugamos, aprendemos. ¿Qué tal si te convertís en empresario u operario por un rato? Simulemos la operatoria del comercio exterior desde la producción hasta la entrega del producto terminado, y tal vez descubras nuevas habilidades y oportunidades.',
    longText: [
      '¡JUGUEMOS A EXPORTAR! es una experiencia única donde te sumergirás en el emocionante mundo del comercio internacional. A través de esta simulación, podrás explorar los procesos involucrados en la exportación de productos, desde la planificación y producción hasta la logística y distribución. Aprende sobre los desafíos y decisiones que enfrentan los empresarios y operarios en el comercio exterior.',
      '#ComercioExterior #SimulaciónEmpresarial #Exportación #LogísticaInternacional #OportunidadesComerciales #AprendizajeInteractivo',
    ],
    imagen: imgjuguemos,
    organizador: orgjuguemos,
  },
  {
    titulo: 'CUANDO MÁS SE PRESIONA…',
    subtitulo: 'Más presiona el sistema   ',
    shortText:
      'El juego de la cerveza es un juego de simulación creado por el MIT Sloan School of y se juega en diferentes cursos de grado y postgrado     ',
    longText: [
      'El juego de la cerveza es un juego de simulación creado por el MIT Sloan School of y se juega en diferentes cursos de grado y postgrado a lo largo del mundo. Consiste en una simulación interactiva de un sistema de producción y de cerveza a lo largo de una cadena constituida por cuatro posiciones: fábrica, el distribuidor, el mayorista y el minorista. Esta actividad ofrece un seguro en donde experimentar y estar en contacto directo con el significativo y vivencial sobre el pensamiento sistémico aplicado a la de suministro.',
      '#PensamientoSistémico #SistemasDeGestión #CadenaDeSuministro #TrabajoEnEquipo #AnálisisDeSistemas #PensamientoCrítico',
    ],
    imagen: imgcuando,
    organizador: orgcuando,
  },
  {
    titulo: 'CONSTRUYENDO CON SCRUM',
    subtitulo: 'Edificando proyectos exitosos con agilidad',
    shortText:
      'Las metodologías ágiles son un mindset. Por eso, para empezar a usarlas, se identifican algunas ideas nuevas y poderosas. Mediante este juego de se presenta el framework SCRUM que permite el trabajo colaborativo entre equipos y acelerar la entrega de valor al cliente.',
    longText: [
      ' La planificación, realización y retrospectivas de sprints de SCRUM, introduce el enfoque ágil en la gestión de proyectos.',
      '#AgileManagement #SCRUM #Sprint #Retrospectiva #Historia de usuarios',
    ],
    imagen: imgconstruyendo,
    organizador: orgconstruyendo,
  },
  {
    titulo: 'DOMINANDO REAL TIME ANALYTICS',
    subtitulo: 'Conocé el futuro del business intelligence',
    shortText:
      'Una de las principales habilidades solicitadas en el mercado laboral de hoy es visualizar toda la información de una empresa en paneles o gráficos que nos permitan tomar buenas decisiones.',
    longText: [
      'Invitamos a este taller para conocer y aprender a usar nuestro Gestor Dinámico de Información (GDI): una herramienta única en su género, superadora a los tradicionales Business Intelligence.',
      '#Crear #Animarse #Jugar',
    ],
    imagen: imgdominando,
    organizador: orgdominando,
  },
  {
    titulo: 'DE HOMO SAPIENS A HOMO INGENIERUS',
    subtitulo: '¿pensamos o nos piensan? Descúbrelo',
    shortText:
      'Vamos a realizar un viaje por la mecánica del cerebro, y comprender su funcionamiento, entendiendo el impacto que tiene este órgano sobre nuestros emociones, percepciones y sobre todo las decisiones que tomamos día a día. ',
    longText: [
      'Muchas veces pensamos que somos libres de las decisiones que tomamos, y más que nada cómo nos relacionamos con los demás: compañeros, jefes, familia, entre otros. Vamos a descubrir juntos cómo los condicionamientos de la sociedad, los tuyos propios y familiares, son los que nos hacen reaccionar frente a los hechos que se producen día a día.',
      'Realizaremos algunos experimentos que té llevaran a comprender tu relación con el cerebro y la toma de decisiones.',
      '¿Te gustaría ver tu día a día en forma diferente, siendo más libre y más completo y en armonía con el mundo? ¡Entonces este taller es para vos!',
      '#Emociones #Pensamientos #Mindfulness #BiologíaDelPresente',
    ],
    imagen: imghomosapiens,
    organizador: orghomosapiens,
  },
  {
    titulo: 'INTELIGENCIA ARTIFICIAL E INTELIGENCIA CREATIVA EN INGENIERÍA',
    subtitulo: '',
    shortText:
      'En este taller vas a poder explorar la sinergia entre la inteligencia creativa y la artificial en ingeniería, entender el impacto de la IA en la resolución de problemas y practicar técnicas de creatividad aplicada para incorporar a tus proyectos.',
    longText: [
      'Vamos a explorar nuevas perspectivas en un mundo donde la ingeniería y la IA se unen para impulsar la innovación.',
      '#InteligenciaCreativa #Innovación #TrabajoEnEquipo #InteligenciaArtificial',
    ],
    imagen: imginteligencia,
    organizador: orginteligencia,
  },
  {
    titulo: 'INGENIERILAB',
    subtitulo: 'Explorando el Design Thinking',
    shortText:
      'Ingenierilab es una experiencia inmersiva diseñada para estudiantes de ingeniería que desean desarrollar habilidades creativas y de resolución de problemas a través del enfoque innovador del Design Thinking.',
    longText: [
      'A lo largo de esta actividad interactiva y dinámica, los participantes explorarán cómo aplicar los principios del Design Thinking a los desafíos de ingeniería del mundo real.',
      '#DesingThinking #Prototipado #DiseñoDeProducto',
    ],
    imagen: imgingenierilab,
    organizador: orgingenierilab,
  },
  {
    titulo: 'LINKEDIN - MARCA PERSONAL',
    subtitulo: 'cómo construirla optimizando tu perfil profesional',
    shortText:
      'LinkedIn es la red de profesionales por excelencia, más de 800 millones de en el mundo así lo avalan. Por eso, es tan importante conocer cómo funciona, cuál es la manera de destacarte cómo profesional y cómo posicionarte en tu sector.',
    longText: [
      'Si estás comenzando tu carrera o estás por graduarte, poseer un perfil optimizado de LinkedIn podría ayudarte a: conseguir un empleo, desarrollarte como freelancer, contactarte con otros profesionales del sector, generar networking, y la lista podría continuar…',
      'Por eso, te invitamos a ser parte del Taller: “Marca Personal, cómo construirla optimizando tu perfil profesional”. Conocerás cómo comenzar a construir tu perfil profesional, potenciando tu marca personal y entendiendo el funcionamiento de esta red.',
      '#MarcaPersonal #LinkedIn #Profesionales #Networking #DesarrolloDeCarrera',
    ],
    imagen: imglinkedin,
    organizador: orglinkedin,
  },
  {
    titulo: 'AL RITMO DEL EQUIPO',
    subtitulo: 'Explorando la sinfonía de la colaboración',
    shortText:
      'El taller tiene como objetivo entrenar aspectos relativos a la competencia del trabajo en equipo, de un modo innovador. Trabajando este entrenamiento a través de la comunicación, la coordinación y la gestión de la incertidumbre.',
    longText: [
      'AL RITMO DEL EQUIPO es un taller diseñado para sumergir a los participantes en una experiencia única de colaboración y sinergia. A través de actividades interactivas y desafiantes, los participantes explorarán cómo la comunicación efectiva, la coordinación fluida y la habilidad para abrazar la incertidumbre son elementos clave para un equipo exitoso.',
      'En un entorno que simula situaciones del mundo real, los participantes se embarcarán en una jornada de descubrimiento y aprendizaje. Desde la planificación de tareas hasta la toma de decisiones bajo presión, AL RITMO DEL EQUIPO ofrece una plataforma para fortalecer las habilidades necesarias en el entorno laboral actual.',
      '#Equipo #Comunicación #Innovación #Colaboración #GestiónDeIncertidumbre',
    ],
    imagen: imgalritmo,
    organizador: orgalritmo,
  },
];
